import React from "react";
import DoubleItem from "./DoubleItem";
import classes from "./OfferSectionBoxes.module.css";
import companies from "./companies";

// Import logos
import abcamLogo from "../assets/images/abcam.webp";
import abtLogo from "../assets/images/ABT_logo.png";
import amLogo from "../assets/images/activemotif.webp";
import antibodiesComLogo from "../assets/images/antibodies.comLogo.png";
import candorLogo from "../assets/images/logo-candor.svg";
import cyanagenLogo from "../assets/images/cyanagen.webp";
import everestLogo from "../assets/images/everest.png";
import finetestLogo from "../assets/images/fntest.webp";
import genedirexLogo from "../assets/images/genedirex.webp";
import ictLogo from "../assets/images/ict.webp";
import idtLogo from "../assets/images/idt.webp";
import invitekLogo from "../assets/images/invitek-1.webp";
import molnovaLogo from "../assets/images/molnova.png";
import primerdesignLogo from "../assets/images/primer-logo.png";
import starlabLogo from "../assets/images/starlab.webp";
import targetmolLogo from "../assets/images/targetmol.webp";
import unitmaLogo from "../assets/images/unitma.png";
import uvpLogo from "../assets/images/uvp.webp";
import zellxLogo from "../assets/images/ZELLXlogo.png";
import ProteintechLogo from "../assets/images/ProteintechLogo.png";

// Logo map for dynamic access
const logoMap = {
    abcamLogo,
    abtLogo,
    amLogo,
    antibodiesComLogo,
    candorLogo,
    cyanagenLogo,
    everestLogo,
    finetestLogo,
    genedirexLogo,
    ictLogo,
    idtLogo,
    invitekLogo,
    molnovaLogo,
    primerdesignLogo,
    starlabLogo,
    targetmolLogo,
    unitmaLogo,
    uvpLogo,
    zellxLogo,
    ProteintechLogo
};
function OfferSectionBoxes() {
    const listItems = companies.map((company, index) => {
        // Check for the existence of company[1] and company[2]
        const firstItemExists = company[1]?.id;
        const secondItemExists = company[2]?.id;

        return (
            <div key={index} className={classes.singleItem__box}>
                {firstItemExists && (
                    <DoubleItem
                        key={company[1].id}
                        href={company[1].href}
                        company={company[1].company}
                        text={company[1].text}
                        logo={logoMap[company[1].logo]} // Dynamically get logo from the map
                        alt={company[1].alt}
                    />
                )}
                {secondItemExists && (
                    <DoubleItem
                        key={company[2].id}
                        href={company[2].href}
                        company={company[2].company}
                        text={company[2].text}
                        logo={logoMap[company[2].logo]} // Dynamically get logo from the map
                        alt={company[2].alt}
                    />
                )}
            </div>
        );
    });

    return (
        <section className={classes.main__wrapper}>
            <div className={classes.main__box}>
                <h1>Dysponujemy pełnym asortymentem wielu światowej klasy producentów</h1>
                <hr />
                {listItems}
            </div>
        </section>
    );
}

export default OfferSectionBoxes;