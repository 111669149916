import React, {useEffect} from "react";
import styles from "./Modal.module.css"; // Import CSS module for styles

const Modal = ({isOpen, onClose, children}) => {

    useEffect(() => {
        if (isOpen) {
            // Disable scrolling
            document.body.style.overflow = "hidden";
        } else {
            // Re-enable scrolling
            document.body.style.overflow = "auto";
        }

        // Cleanup function to re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    if (!isOpen) return null; // Don't render the modal if it's not open
    return (
        <div className={styles.overlay} onClick={onClose}>
            <div
                className={styles.modal}
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                {children}
                <button className={styles.closeButton} onClick={onClose}>
                    Zamknij
                </button>
            </div>
        </div>
    );
};

export default Modal;