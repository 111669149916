import React, {useEffect, useRef, useState} from "react";

import InputField from "./InputField";
import {TailSpin} from "react-loader-spinner";
import classes from "./Rodo.module.css";
import Modal from "./Modal";

function PDDForm() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [firstAgreementCheckbox, setFirstAgreementCheckbox] = useState(true);
    const [secondAgreementCheckbox, setsecondAgreementCheckbox] = useState(true);
    const [diagnosticsCheckbox, setDiagnosticsCheckbox] = useState(false);
    const [grantEUCheckbox, setGrantEUCheckbox] = useState(false);
    const [grantNCNCheckbox, setGrantNCNCheckbox] = useState(false);
    const [easyOfUseCheckbox, setEasyOfUseCheckbox] = useState(false);
    const [testsCheckbox, setTestsCheckbox] = useState(false);
    const [qualityCheckbox, setqualityCheckbox] = useState(false);
    const [complexBuyingCheckbox, setComplexBuyingCheckbox] = useState(false);
    const [parametersCheckbox, setParametersCheckbox] = useState(false);
    const [promotionInfosCheckbox, setpromotionInfosCheckbox] = useState(false);
    const [literatureInfosCheckbox, setliteratureInfosCheckbox] = useState(false);
    const [spinnerActive, setspinnerActive] = useState(false);
    const [variable1] = useState(Math.round(Math.random() * 25));
    const [variable2] = useState(Math.round(Math.random() * 25));

    const captchaInput = useRef(0);

    const [queryData, setQueryData] = useState({
        name: "",
        pdate: "",
        title: "",
        institution: "",
        address: "",
        email: "",
        phone: "",
        manager: "",
        people: "",
        complexBuying: false,
        diagnostics: false,
        easyOfUse: false,
        grantEU: false,
        grantNCN: false,
        parameters: false,
        quality: false,
        promotionInfos: "",
        literatureInfos: "",
        firstagreement: true,
        secondagreement: true,
    });
    useEffect(() => {
        console.log("Updated queryData:", queryData);
    }, [queryData]);
    const inputChangeHandler = (info, indicator) => {
        switch (indicator) {
            case "name": {
                setQueryData((prev) => ({...prev, name: info}));
                break;
            }
            case "pdate": {
                setQueryData((prev) => ({...prev, pdate: info}));
                break;
            }
            case "title": {
                setQueryData((prev) => ({...prev, title: info}));
                break;
            }
            case "institution": {
                setQueryData((prev) => ({...prev, institution: info}));
                break;
            }
            case "address": {
                setQueryData((prev) => ({...prev, address: info}));
                break;
            }
            case "email": {
                setQueryData((prev) => ({...prev, email: info}));
                break;
            }
            case "phone": {
                setQueryData((prev) => ({...prev, phone: info}));
                break;
            }
            case "manager": {
                setQueryData((prev) => ({...prev, manager: info}));
                break;
            }
            case "people": {
                setQueryData((prev) => ({...prev, people: info}));
                break;
            }
            case "thematic": {
                setQueryData((prev) => ({...prev, thematic: info}));
                break;
            }
            case "tests": {
                setQueryData((prev) => ({...prev, tests: info}));
                break;
            }
            case "diagnostics": {
                setQueryData((prev) => ({...prev, diagnostics: info}));
                break;
            }
            case "grantEU": {
                setQueryData((prev) => ({...prev, grantEU: info}));
                break;
            }
            case "grantNCN": {
                setQueryData((prev) => ({...prev, grantNCN: info}));
                break;
            }
            case "easyOfUse": {
                setQueryData((prev) => ({...prev, easyOfUse: info}));
                break;
            }
            case "quality": {
                setQueryData((prev) => ({...prev, quality: info}));
                break;
            }
            case "complexBuying": {
                setQueryData((prev) => ({...prev, complexBuying: info}));
                break;
            }
            case "parameters": {
                setQueryData((prev) => ({...prev, parameters: info}));
                break;
            }
            case "promotionInfos": {
                setQueryData((prev) => ({...prev, promotionInfos: info}));
                break;
            }
            case "literatureInfos": {
                setQueryData((prev) => ({...prev, literatureInfos: info}));
                break;
            }
            case "firstagreement": {
                setQueryData((prev) => ({...prev, firstagreement: info}));
                break;
            }
            case "secondagreement": {
                setQueryData((prev) => ({...prev, secondagreement: info}));
                break;
            }
            default:
                break;
        }

    };

    const sendmail = (e) => {
        e.preventDefault();
        if (
            Number(captchaInput.current.value) === variable1 + variable2 &&
            queryData.name.trim().length !== 0 &&
            queryData.pdate.trim().length !== 0 &&
            queryData.title.trim().length !== 0 &&
            queryData.institution.trim().length !== 0 &&
            queryData.address.trim().length !== 0 &&
            queryData.email.trim().length !== 0 &&
            queryData.phone.trim().length !== 0 &&
            queryData.manager.trim().length !== 0 &&
            queryData.people.trim().length !== 0 &&
            queryData.firstagreement &&
            queryData.secondagreement
        ) {            setspinnerActive(true);
            window.Email.send({
                SecureToken: "ef532d75-22a3-4326-91a9-089b622e0b82",
                From: "Website@symbios.pl",
                To: "Website@symbios.pl",
                Subject: "Formularz PDD",
                Body: `
    <div style="background-color: #f4f4f4; padding: 20px; font-family: Arial, sans-serif;">
    
        <!-- HEADER -->
        <div style="text-align: center; background-color: #0056b3; color: white; padding: 15px; border-radius: 10px;">
            <h2>Formularz Zgłoszeniowy - Program Dla Doktorantów</h2>
        </div>

        <div style="background-color: white; padding: 20px; border-radius: 10px; margin-top: 10px;">
        
            <!-- GENERAL INFORMATION -->
            <h3 style="color: #0056b3;">Dane Osobowe</h3>
            <p><strong>Imię i nazwisko:</strong> ${queryData.name}</p>
            <p><strong>Data przystąpienia do PDD:</strong> ${queryData.pdate}</p>
            <p><strong>Tytuł, Imię i Nazwisko kierownika zakładu:</strong> ${queryData.title}</p>
            <p><strong>Instytucja/Zakład:</strong> ${queryData.institution}</p>
            <p><strong>Adres zakładu:</strong> ${queryData.address}</p>
            <p><strong>Adres e-mail:</strong> ${queryData.email}</p>
            <p><strong>Numer telefonu:</strong> ${queryData.phone}</p>
            <p><strong>Tytuł, Imię i nazwisko promotora:</strong> ${queryData.manager}</p>
            <p><strong>Skład grupy asystenckiej i/lub doktoranckiej:</strong> ${queryData.people}</p>

            <!-- RESEARCH SCALE -->
            <h3 style="color: #0056b3; margin-top: 20px;">Skala Badań</h3>
            <p><strong>Diagnostyka:</strong> ${queryData.diagnostics ? "TAK" : "NIE"}</p>
            <p><strong>Badania:</strong> ${queryData.tests ? "TAK" : "NIE"}</p>
            <p><strong>Grant UE:</strong> ${queryData.grantEU ? "TAK" : "NIE"}</p>
            <p><strong>Grant NCN:</strong> ${queryData.grantNCN ? "TAK" : "NIE"}</p>
            
            <!-- THEMATIC AREA -->
            <h3 style="color: #0056b3; margin-top: 20px;">Tematyka</h3>
            <p>${queryData.thematic}</p>

            <!-- PRODUCT FEATURES -->
            <h3 style="color: #0056b3; margin-top: 20px;">Cechy Produktów Istotne dla Klienta</h3>
            <p><strong>Łatwość użytkowania:</strong> ${queryData.easyOfUse ? "TAK" : "NIE"}</p>
            <p><strong>Jakość:</strong> ${queryData.quality ? "TAK" : "NIE"}</p>
            <p><strong>Kompleksowość zakupu:</strong> ${queryData.complexBuying ? "TAK" : "NIE"}</p>
            <p><strong>Parametry:</strong> ${queryData.parameters ? "TAK" : "NIE"}</p>

            <!-- ADDITIONAL INFORMATION -->
            <h3 style="color: #0056b3; margin-top: 20px;">Informacje Dodatkowe</h3>
            <p><strong>Informacje o promocjach:</strong> ${queryData.promotionInfos ? "TAK" : "NIE"}</p>
            <p><strong>Informacje o literaturze:</strong> ${queryData.literatureInfos ? "TAK" : "NIE"}</p>

            <!-- CONSENT -->
            <h3 style="color: #d9534f; margin-top: 20px;">Zgody</h3>
            <p>
                Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przygotowania, złożenia i realizacji ofert i/lub umów handlowych dotyczących produktów do badań i diagnostyki z obszaru „life science” przez Symbios Sp. z o.o oraz promocji produktów i usług Symbios Sp. z o.o. poprzez telekomunikacyjne urządzenia końcowe (kontakt mailowy i telefoniczny) dla celów marketingu bezpośredniego Symbios zgodnie z Ustawą z dnia 16 lipca 2004 r. Prawo telekomunikacyjne. 
                [${queryData.firstagreement ? "TAK" : "NIE"}]
            </p>
            <p>
                Oświadczam, że zapoznałam/em się z treścią klauzuli informacyjnej oraz regulaminem Programu Dla Doktorantów dostępnym na stronie <a href="https://www.symbios.pl/PDD" style="color: #0056b3;">www.symbios.pl/PDD</a>. 
                [${queryData.secondagreement ? "TAK" : "NIE"}]
            </p>
        </div>

        <!-- FOOTER -->
        <div style="text-align: center; background-color: #0056b3; color: white; padding: 10px; border-radius: 10px; margin-top: 20px;">
            <p>© ${new Date().getFullYear()} Symbios Sp. z o.o. | <a href="https://www.symbios.pl" style="color: white; text-decoration: none;">www.symbios.pl</a></p>
        </div>

    </div>
`,
            }).then((message) => {
                setspinnerActive(false);
                if (message === "OK") {
                    localStorage.setItem("cart", "[]");
                }
                setModalMessage(
                    message === "OK"
                        ? "Zgoda RODO została pomyślnie wysłana"
                        : "Wystąpił błąd, spróbuj ponownie później lub skontaktuj się z odpowiedzialnym za Twój rynek doradcą"
                );
                setIsModalOpen(true); // Show modal
            });
        } else {
            setspinnerActive(false);
            setModalMessage("Wszystkie pola oznaczone * muszą zostać wypełnione");
            setIsModalOpen(true); // Show modal
        }
    };

    return (<section className={classes.rodo__form}>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <p>{modalMessage}</p>
        </Modal>
            <h2 className={classes.rodo__heading}>Formularz przystapienia do Programu Dla Doktorantow (PDD)</h2>
            <form action="" method="">
                <div className={classes.container__row}>
                    <InputField
                        text="Imię i Nazwisko"
                        type="text"
                        name="name"
                        required="true"
                        autocomplete="on" inputChangeHandler={inputChangeHandler}
                    />
                    <InputField
                        text="Data przystapienia do PDD"
                        type="date"
                        name="pdate"
                        required="true"
                        autocomplete="on" inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={classes.container__row}>
                    <InputField
                        text="Tytul, Imie i Nazwisko kierownika zakładu"
                        type="text"
                        name="title"
                        required="true"
                        autocomplete="on" inputChangeHandler={inputChangeHandler}
                    />
                    <InputField
                        text="Instytucja/Zakład"
                        type="text"
                        name="institution"
                        required="true"
                        autocomplete="on" inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={classes.container__row}>
                    <InputField
                        text="Adres zakładu"
                        type="text"
                        name="address"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                    <InputField
                        text="Adres e-mail"
                        type="email"
                        name="email"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={classes.container__row}>
                    <InputField
                        text="Telefon"
                        type="tel"
                        name="phone"
                        required="true"
                        autocomplete="on" inputChangeHandler={inputChangeHandler}
                    />
                    <InputField
                        text="Tytuł, Imię i nazwisko promotora"
                        type="text"
                        name="manager"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <InputField
                        text="Skład grupy asystenckiej i/lub doktoranckiej (imię i nazwisko, temat pracy, materiał badawczy, stosowane techniki)"
                        type="textarea"
                        name="people"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <div className={classes.container__checkboxwrapper}>
                        <p> Skala badań:</p>
                        <input type="checkbox" id="diagnostics" name="diagnostics"
                               value={diagnosticsCheckbox}
                               checked={diagnosticsCheckbox}
                               onChange={(e) => {
                                   setDiagnosticsCheckbox(!diagnosticsCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="diagnostics">diagnostyka</label>

                        <input type="checkbox" id="tests" name="tests"
                               value={testsCheckbox}
                               checked={testsCheckbox}
                               onChange={(e) => {
                                   setTestsCheckbox(!testsCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="tests">badania naukowe</label>

                        <input type="checkbox" id="grantEU" name="grantEU" value={grantEUCheckbox}
                               checked={grantEUCheckbox}
                               onChange={(e) => {
                                   setGrantEUCheckbox(!grantEUCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="grantEU">grant EU</label>

                        <input type="checkbox" id="grantNCN" name="grantNCN" value={grantNCNCheckbox}
                               checked={grantNCNCheckbox}
                               onChange={(e) => {
                                   setGrantNCNCheckbox(!grantNCNCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="grantNCN">grant NCN</label>
                    </div>
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <InputField
                        text="Tematyka:"
                        type="text"
                        name="thematic"
                        required="true"
                        autocomplete="on"
                        inputChangeHandler={inputChangeHandler}
                    />
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <div className={classes.container__checkboxwrapper}>
                        <p>Cechy produktów istotne dla klienta:</p>
                        <input type="checkbox" id="easyOfUse" name="easyOfUse"
                               value={easyOfUseCheckbox}
                               checked={easyOfUseCheckbox}
                               onChange={(e) => {
                                   setEasyOfUseCheckbox(!easyOfUseCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="easyOfUse">Łatwość użycia</label>

                        <input type="checkbox" id="quality" name="quality"
                               value={qualityCheckbox}
                               checked={qualityCheckbox}
                               onChange={(e) => {
                                   setqualityCheckbox(!qualityCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="quality">Jakość</label>

                        <input type="checkbox" id="complexBuying" name="complexBuying"
                               value={complexBuyingCheckbox}
                               checked={complexBuyingCheckbox}
                               onChange={(e) => {
                                   setComplexBuyingCheckbox(!complexBuyingCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="complexBuying">Możliwość kompleksowego zakupu w jednej
                            firmie</label>

                        <input type="checkbox" id="parameters" name="parameters"
                               value={parametersCheckbox}
                               checked={parametersCheckbox}
                               onChange={(e) => {
                                   setParametersCheckbox(!parametersCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="parameters">Szczególne parametry produktu</label>
                    </div>
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                    <div className={classes.container__checkboxwrapper}>
                        <p>Jestem zainteresowany informacjami na temat kolejnych akcji promocyjnych :</p>
                        <input type="checkbox" id="promotionInfos" name="promotionInfos"
                               value={promotionInfosCheckbox}
                               checked={promotionInfosCheckbox}
                               onChange={(e) => {
                                   setpromotionInfosCheckbox(!promotionInfosCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="promotionInfos">Tak</label>
                    </div>
                </div>
                <div className={`${classes.container__row} ${classes["container__row"]}`}>
                <div className={classes.container__checkboxwrapper}>
                        <p>Jestem zainteresowany otrzymywaniem literatury i katalogów związanych tematycznie z zakresem
                            mojej
                            pracy</p>
                        <input type="checkbox" id="literatureInfos" name="literatureInfos"
                               value={literatureInfosCheckbox}
                               checked={literatureInfosCheckbox}
                               onChange={(e) => {
                                   setliteratureInfosCheckbox(!literatureInfosCheckbox);
                                   inputChangeHandler(e.target.checked, e.target.name);
                               }}/>
                        <label htmlFor="literatureInfos">Tak</label>
                    </div>
                </div>
                <div className={classes["container__row--textarea"]}>
                    <div className={classes.container__agreementswrapper}>
                        <h3 className={classes.information__heading}>KLAUZULA INFORMACYJNA DLA UCZESTNIKÓW PDD</h3>

                        <p className={classes.information__paragraph}>Mając na uwadze wymogi art. 13 ust. 1 i ust. 2
                            Rozporządzenia Parlamentu Europejskiego i Rady (UE)
                            2016/679 z dnia 27 kwietnia 2016 r. (RODO), informujemy, że Administratorem Państwa danych
                            osobowych będzie spółka Symbios Sp. z o.o. z siedzibą w Gdańsk 80-286, ul. Jaśkowa Dolina
                            68.
                            Jednocześnie informujemy, że z Inspektorem Ochrony Danych Osobowych można kontaktować się
                            pod
                            adresem e-mail: RODO@symbios.pl. Podanie danych osobowych jest dobrowolne oraz niezbędne do
                            rozsyłania newslettera, przygotowania, złożenia i realizacji ofert i/lub umów handlowych
                            dotyczących
                            produktów do badań i diagnostyki z obszaru „life science”, pomiędzy Państwem a Symbios.
                            Państwa
                            dane (imię, nazwisko, nr telefonu służbowego, adres e-mail) będą przetwarzane w celu
                            wykonania
                            umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie
                            osoby, której
                            dane dotyczą, przed zawarciem umowy (art. 6 ust. 1 lit. b RODO) lub na podstawie Państwa
                            zgody (art.
                            6 ust. 1 lit. a RODO). Zgodę można odwołać w dowolnym momencie, poprzez kontakt z Symbios
                            RODO@symbios.pl lub pisemnie na adres administratora. Symbios informuje ponadto, że dane
                            osobowe będą przechowywane do czasu wycofania zgody lub do czasu ustania celu ich
                            przetwarzania
                            i nie będą udostępniane podmiotom nieupoważnionym, ani przekazywane do państw trzecich.
                            Mają Państwo prawo do żądania dostępu do swoich danych osobowych, ich sprostowania,
                            usunięcia
                            lub ograniczenia przetwarzania, prawo do wniesienia sprzeciwu wobec przetwarzania, a także
                            prawo
                            do przenoszenia danych. Przysługuje Państwu również prawo wniesienia skargi do organu
                            nadzorczego
                            - Prezesa Urzędu Ochrony Danych Osobowych. </p>

                        <div className={classes.label__box}>
                            <input
                                type="checkbox"
                                id="firstagreement"
                                name="firstagreement"
                                value={firstAgreementCheckbox}
                                checked={firstAgreementCheckbox}
                                onChange={(e) => {
                                    setFirstAgreementCheckbox(firstAgreementCheckbox);
                                    inputChangeHandler(true, e.target.name);
                                }}
                            />
                            <label htmlFor="firstagreement">
                                Wyrażam zgodę na przetwarzanie moich danych osobowych w celu przygotowania, złożenia i
                                realizacji ofert i/lub umów handlowych dotyczących produktów do
                                badań i diagnostyki z obszaru „life science” przez Symbios Sp. z o.o oraz promocji
                                produktów i usług Symbios Sp. z o.o. poprzez telekomunikacyjne urządzenia końcowe
                                (kontakt mailowy i telefoniczny) dla celów marketingu bezpośredniego Symbios zgodnie z
                                Ustawą z dnia 16 lipca 2004 r. Prawo telekomunikacyjne. *
                            </label>
                        </div>
                        <div className={classes.label__box}>
                            <input
                                type="checkbox"
                                id="secondagreement"
                                name="secondagreement"
                                value={secondAgreementCheckbox}
                                checked={secondAgreementCheckbox}
                                onChange={(e) => {
                                    setsecondAgreementCheckbox(secondAgreementCheckbox);
                                    inputChangeHandler(true, e.target.name);
                                }}
                            />
                            <label htmlFor="secondagreement">
                                Oświadczam, że zapoznałam/em się z treścią klauzuli informacyjnej oraz regulaminem
                                Programu Dla Doktorantów dostępnym na stronie {" "}
                                <a
                                    href="/PDD" rel="noreferrer" target="_blank">
                                    www.symbios.pl/PDD.
                                </a> *
                            </label>
                        </div>
                    </div>
                </div>
                <div className={classes.captcha__styling}>
                    <label htmlFor="secondagreement">Ochrona anty-spamowa:</label>
                    <div className={classes.captcha__checker}>
                        <span>{variable1}</span> + <span>{variable2} = </span>
                        <input type="number" ref={captchaInput} className={classes.captcha__input}/>
                    </div>
                    {spinnerActive && (<TailSpin
                        height="40"
                        width="40"
                        color="#1c3975"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{marginTop: "2rem"}}
                        wrapperClass=""
                        visible={true}
                    />)}
                    <button className={classes.send__button} onClick={sendmail}>
                        Prześlij zgodę
                    </button>

                </div>
            </form>
    </section>);
}

export default PDDForm;
