import React from "react";
import classes from "./SingleItem.module.css";

function SingleItem(props) {
  return (
    <div className={classes.singleItem__box}>
        <div className={classes.column__left}>
            <h2>{props.company}</h2>
            <p dangerouslySetInnerHTML={{__html: props.text}}></p>
        </div>
        <div className={classes.column__right}>
            <a href={props.href}>
          <img src={props.logo} alt={props.alt} />
        </a>
      </div>
    </div>
  );
}

export default SingleItem;
