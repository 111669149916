import React from "react";
import SingleItem from "../components/asortimentSnippetSection/SingleItem";
import abcamLogo from "../components/assets/images/abcam.webp";
import abtLogo from "../components/assets/images/ABT_logo.png";
import amLogo from "../components/assets/images/activemotif.webp";
import antibodiesComLogo from "../components/assets/images/antibodies.comLogo.png";
import candorLogo from "../components/assets/images/logo-candor.svg";
import classes from "./PartnersBox.module.css";
import cyanagenLogo from "../components/assets/images/cyanagen.webp";
import everestLogo from "../components/assets/images/everest.png";
import finetestLogo from "../components/assets/images/fntest.webp";
import genedirexLogo from "../components/assets/images/genedirex.webp";
import ictLogo from "../components/assets/images/ict.webp";
import idtLogo from "../components/assets/images/idt.webp";
import invitekLogo from "../components/assets/images/invitek-1.webp";
import molnovaLogo from "../components/assets/images/molnova.png";
import primerdesignLogo from "../components/assets/images/primer-logo.png";
import starlabLogo from "../components/assets/images/starlab.webp";
import targetmolLogo from "../components/assets/images/targetmol.webp";
import unitmaLogo from "../components/assets/images/unitma.png";
import uvpLogo from "../components/assets/images/ajena.png";
import zellxLogo from "../components/assets/images/ZELLXlogo.png";
import ProteintechLogo from "../components/assets/images/ProteintechLogo.png";
import companies from "./offerSectionBoxes/companies";

const logoMap = {
    abcamLogo,
    abtLogo,
    amLogo,
    antibodiesComLogo,
    candorLogo,
    cyanagenLogo,
    everestLogo,
    finetestLogo,
    genedirexLogo,
    ictLogo,
    idtLogo,
    invitekLogo,
    molnovaLogo,
    primerdesignLogo,
    starlabLogo,
    targetmolLogo,
    unitmaLogo,
    uvpLogo,
    zellxLogo,
    ProteintechLogo
};

function PartnersBox() {
    const combinedListItems = companies.flatMap((company) => {
        const items = [];

        // Add item from group 1 if it exists
        if (company[1]?.id) {
            items.push(
                <SingleItem
                    key={`group1-${company[1].id}`} // Unique key with group identifier
                    href={company[1].href}
                    company={company[1].company}
                    text={company[1].text}
                    logo={logoMap[company[1].logo]}
                    alt={company[1].alt}
                />
            );
        }

        // Add item from group 2 if it exists
        if (company[2]?.id) {
            items.push(
                <SingleItem
                    key={`group2-${company[2].id}`} // Unique key with group identifier
                    href={company[2].href}
                    company={company[2].company}
                    text={company[2].text}
                    logo={logoMap[company[2].logo]}
                    alt={company[2].alt}
                />
            );
        }

        return items;
    });

    return (
        <section className={classes.main__wrapper}>
            <div className={classes.main__box}>
                <h1>Nasi Partnerzy:</h1>
                <hr/>
                <div className={classes.size__reducer}>{combinedListItems}</div>
            </div>
        </section>
    );
}

export default PartnersBox;
